const TerminationShow = ({
  supplementaryFileType,
  terminationContractCategory,
  terminationContractFullName,
  terminationContractNotes,
  terminationContractPaymentDate,
  terminationContractRequestDate,
  terminationContractSigningDate,
  terminationContractTerminationAmount,
  terminationContractTerminationType
}) => {
  return (

    <div>
      <div style={{ textAlign: "center", marginBottom: "10vh" }}>
        <h3>DETALLES DEL CONTRATO</h3>
      </div>
      <div >
        <h2><b>{supplementaryFileType}</b></h2>
      </div>
      <div>
        <p>
          <b>Categoria:</b> {terminationContractCategory}
        </p>
        <p>
          <b>Nombre Completo:</b> {terminationContractFullName}
        </p>
        <p>
          <b>Notas:</b> {terminationContractNotes}
        </p>
        <p>
          <b>Fecha de Pago:</b> {terminationContractPaymentDate}
        </p>
        <p>
          <b>Fecha de solicitud:</b> {terminationContractRequestDate}
        </p>
        <p>
          <b>Fecha de firma:</b> {terminationContractSigningDate}
        </p>
        <p>
          <b>Monto por finiquito:</b> {terminationContractTerminationAmount}
        </p>
        <p>
          <b>Aplicado A:</b> {terminationContractTerminationType}
        </p>
      </div>
    </div>
  );
}

export default TerminationShow;