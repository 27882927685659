import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { useParams } from 'react-router-dom';
import ModificationAgreementShow from "./ModificationAgreementShow";
import EmploymentContractShow from "./EmploymentContractShow";
import RepresentationContractShow from "./RepresentationContractShow";
import TerminationShow from "./TerminationShow";
import TransferContractShow from "./TransferContractShow";

const ContractDetails = ({ token, currentUser, onLogout }) => {
  const { id } = useParams();
  const [supplementaryFileType, setSupplementaryFileType] = useState("");

  // Modification Agreement Fields
  const [category, setCategory] = useState("");
  const [fullName, setFullName] = useState("");
  const [modificationsToMake, setModificationsToMake] = useState("");
  const [requestDate, setRequestDate] = useState("");

  // Employment Contract Fields
  const [employmentContractAddress, setEmploymentContractAddress] = useState("");
  const [employmentContractPosition, setEmploymentContractPosition] = useState("");
  const [employmentContractAnnualSalary, setEmploymentContractAnnualSalary] = useState("");
  const [employmentContractCategory, setEmploymentContractCategory] = useState("");
  const [employmentContractClubTerminationClause, setEmploymentContractClubTerminationClause] = useState("");
  const [employmentContractContractDuration, setEmploymentContractContractDuration] = useState(null);
  const [employmentContractContractId, setEmploymentContractContractId] = useState("");
  const [employmentContractContractType, setEmploymentContractContractType] = useState("");
  const [employmentContractDateOfBirth, setEmploymentContractDateOfBirth] = useState(null);
  const [employmentContractDirectedTo, setEmploymentContractDirectedTo] = useState("");
  const [employmentContractEmail, setEmploymentContractEmail] = useState("");
  const [employmentContractFirstPaymentDate, setEmploymentContractFirstPaymentDate] = useState(null);
  const [employmentContractFreeAgentOrTransfer, setEmploymentContractFreeAgentOrTransfer] = useState("");
  const [employmentContractFullName, setEmploymentContractFullName] = useState("");
  const [employmentContractJurisdiction, setEmploymentContractJurisdiction] = useState("");
  const [employmentContractLastPaymentDate, setEmploymentContractLastPaymentDate] = useState(null);
  const [employmentContractMaritalStatus, setEmploymentContractMaritalStatus] = useState("");
  const [employmentContractNationality, setEmploymentContractNationality] = useState("");
  const [employmentContractOtherBenefits, setEmploymentContractOtherBenefits] = useState("");
  const [employmentContractPaymentFrequency, setEmploymentContractPaymentFrequency] = useState("");
  const [employmentContractPlayerTerminationClause, setEmploymentContractPlayerTerminationClause] = useState("");
  const [employmentContractRelocation, setEmploymentContractRelocation] = useState("");
  const [employmentContractRequestDate, setEmploymentContractRequestDate] = useState(null);
  const [employmentContractSigningDate, setEmploymentContractSigningDate] = useState(null);
  const [employmentContractSportsBonuses, setEmploymentContractSportsBonuses] = useState("");

  //Representation Contract Fields
  const [representationContractAddress, setRepresentationContractAddress] = useState("");
  const [representationContractBankDetails, setRepresentationContractBankDetails] = useState("");
  const [representationContractConsideration, setRepresentationContractConsideration] = useState("");
  const [representationContractFifaRegistrationNumber, setRepresentationContractFifaRegistrationNumber] = useState("");
  const [representationContractFullName, setRepresentationContractFullName] = useState("");
  const [representationContractNationality, setRepresentationContractNationality] = useState("");
  const [representationContractOtherConditions, setRepresentationContractOtherConditions] = useState("");
  const [representationContractPassportNumber, setRepresentationContractPassportNumber] = useState("");
  const [representationContractRfcOrTaxId, setRepresentationContractRfcOrTaxId] = useState("");

  //Termination Contract Fields
  const [terminationContractCategory, setTerminationContractCategory] = useState("");
  const [terminationContractFullName, setTerminationContractFullName] = useState("");
  const [terminationContractNotes, setTerminationContractNotes] = useState("");
  const [terminationContractPaymentDate, setTerminationContractPaymentDate] = useState("");
  const [terminationContractRequestDate, setTerminationContractRequestDate] = useState("");
  const [terminationContractSigningDate, setTerminationContractSigningDate] = useState("");
  const [terminationContractTerminationAmount, setTerminationContractTerminationAmount] = useState("");
  const [terminationContractTerminationType, setTerminationContractTerminationType] = useState("");

  // Transfer Contract Fields
  const [transferContractClubName, setTransferContractClubName] = useState("");
  const [transferContractClubRepresentative, setTransferContractClubRepresentative] = useState("");
  const [transferContractEmail, setTransferContractEmail] = useState("");
  const [transferContractFreeOrCost, setTransferContractFreeOrCost] = useState("");
  const [transferContractOtherConditions, setTransferContractOtherConditions] = useState("");
  const [transferContractPurchaseOption, setTransferContractPurchaseOption] = useState("");
  const [transferContractSolidarityWithholding, setTransferContractSolidarityWithholding] = useState("");
  const [transferContractTransferType, setTransferContractTransferType] = useState("");
  const [transferContractValidity, setTransferContractValidity] = useState("");


  useEffect(() => {
    axios.get(`https://supplies.bravos-fcjuarez.com/contracts/${id}/supplementary_file_details/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        console.log(response);
        fillSupplementaryFileFields(response.data.supplementary_file_type, response);
      })

    const fillSupplementaryFileFields = (supplementary_file_type, response_fields) => {
      let file_type = ""

      if (supplementary_file_type == "contracts_modificationagreement") {
        file_type = "Convenio modificatorio"; 
        setCategory(response_fields.data.data[0].category);
        setFullName(response_fields.data.data[0].full_name);
        setModificationsToMake(response_fields.data.data[0].modifications_to_make);
        setRequestDate(response_fields.data.data[0].request_date);
      }

      if (supplementary_file_type == "contracts_employmentcontract") {
        file_type = "Contrato de Trabajo";
        setEmploymentContractAddress(response_fields.data.data[0].address);
        setEmploymentContractPosition(response_fields.data.data[0].position);
        setEmploymentContractAnnualSalary(response_fields.data.data[0].annual_salary);
        setEmploymentContractCategory(response_fields.data.data[0].category);
        setEmploymentContractClubTerminationClause(response_fields.data.data[0].club_termination_clause);
        setEmploymentContractContractDuration(response_fields.data.data[0].contract_duration);
        setEmploymentContractContractType(response_fields.data.data[0].contract_type);
        setEmploymentContractDateOfBirth(response_fields.data.data[0].date_of_birth);
        setEmploymentContractDirectedTo(response_fields.data.data[0].directed_to);
        setEmploymentContractEmail(response_fields.data.data[0].email);
        setEmploymentContractFirstPaymentDate(response_fields.data.data[0].first_payment_date);
        setEmploymentContractFreeAgentOrTransfer(response_fields.data.data[0].free_agent_or_transfer);
        setEmploymentContractFullName(response_fields.data.data[0].full_name);
        setEmploymentContractJurisdiction(response_fields.data.data[0].jurisdiction);
        setEmploymentContractLastPaymentDate(response_fields.data.data[0].last_payment_date);
        setEmploymentContractMaritalStatus(response_fields.data.data[0].marital_status);
        setEmploymentContractNationality(response_fields.data.data[0].nationality);
        setEmploymentContractOtherBenefits(response_fields.data.data[0].other_benefits);
        setEmploymentContractPaymentFrequency(response_fields.data.data[0].payment_frequency);
        setEmploymentContractPlayerTerminationClause(response_fields.data.data[0].player_termination_clause);
        setEmploymentContractRelocation(response_fields.data.data[0].relocation);
        setEmploymentContractRequestDate(response_fields.data.data[0].request_date);
        setEmploymentContractSigningDate(response_fields.data.data[0].signing_date);
        setEmploymentContractSportsBonuses(response_fields.data.data[0].sports_bonuses);
      }

      if (supplementary_file_type == "contracts_representationcontract") {
        file_type = "Contrato de Representacion";
        setRepresentationContractAddress(response_fields.data.data[0].address);
        setRepresentationContractBankDetails(response_fields.data.data[0].bank_details);
        setRepresentationContractConsideration(response_fields.data.data[0].consideration);
        setRepresentationContractFifaRegistrationNumber(response_fields.data.data[0].fifa_registration_number);
        setRepresentationContractFullName(response_fields.data.data[0].full_name);
        setRepresentationContractNationality(response_fields.data.data[0].nationality);
        setRepresentationContractOtherConditions(response_fields.data.data[0].other_conditions);
        setRepresentationContractPassportNumber(response_fields.data.data[0].passport_number);
        setRepresentationContractRfcOrTaxId(response_fields.data.data[0].rfc_or_tax_id);
      }

      if (supplementary_file_type == "contracts_termination") {
        file_type = "Contrato de Baja";
        setTerminationContractCategory(response_fields.data.data[0].category);
        setTerminationContractFullName(response_fields.data.data[0].full_name);
        setTerminationContractNotes(response_fields.data.data[0].notes);
        setTerminationContractPaymentDate(response_fields.data.data[0].payment_date);
        setTerminationContractRequestDate(response_fields.data.data[0].request_date);
        setTerminationContractSigningDate(response_fields.data.data[0].signing_date);
        setTerminationContractTerminationAmount(response_fields.data.data[0].termination_amount);
        setTerminationContractTerminationType(response_fields.data.data[0].termination_type);
      }

      if (supplementary_file_type == "contracts_transfercontract") {
        file_type = "Contrato de Transferencia";
        setTransferContractClubName(response_fields.data.data[0].club_name);
        setTransferContractClubRepresentative(response_fields.data.data[0].club_representative);
        setTransferContractEmail(response_fields.data.data[0].email);
        setTransferContractFreeOrCost(response_fields.data.data[0].free_or_cost);
        setTransferContractOtherConditions(response_fields.data.data[0].other_conditions);
        setTransferContractPurchaseOption(response_fields.data.data[0].purchase_option);
        setTransferContractSolidarityWithholding(response_fields.data.data[0].solidarity_withholding);
        setTransferContractTransferType(response_fields.data.data[0].transfer_type);
        setTransferContractValidity(response_fields.data.data[0].validity);
      }
      setSupplementaryFileType(file_type);
    }

  }, [token]);

  return (
    <div className="container">
      <Navbar />
      {supplementaryFileType == "Convenio modificatorio" ? (
        <ModificationAgreementShow
          supplementaryFileType={supplementaryFileType}
          category={category}
          fullName={fullName}
          modificationsToMake={modificationsToMake}
          requestDate={requestDate} />
      ) : null}
      {supplementaryFileType == "Contrato de Trabajo" ? (
        <EmploymentContractShow
          supplementaryFileType={supplementaryFileType}
          employmentContractAddress={employmentContractAddress}
          employmentContractPosition={employmentContractPosition}
          employmentContractAnnualSalary={employmentContractAnnualSalary}
          employmentContractCategory={employmentContractCategory}
          employmentContractClubTerminationClause={employmentContractClubTerminationClause}
          employmentContractContractDuration={employmentContractContractDuration}
          employmentContractContractType={employmentContractContractType}
          employmentContractDateOfBirth={employmentContractDateOfBirth}
          employmentContractDirectedTo={employmentContractDirectedTo}
          employmentContractEmail={employmentContractEmail}
          employmentContractFirstPaymentDate={employmentContractFirstPaymentDate}
          employmentContractFreeAgentOrTransfer={employmentContractFreeAgentOrTransfer}
          employmentContractFullName={employmentContractFullName}
          employmentContractJurisdiction={employmentContractJurisdiction}
          employmentContractLastPaymentDate={employmentContractLastPaymentDate}
          employmentContractMaritalStatus={employmentContractMaritalStatus}
          employmentContractNationality={employmentContractNationality}
          employmentContractOtherBenefits={employmentContractOtherBenefits}
          employmentContractPaymentFrequency={employmentContractPaymentFrequency}
          employmentContractPlayerTerminationClause={employmentContractPlayerTerminationClause}
          employmentContractRelocation={employmentContractRelocation}
          employmentContractRequestDate={employmentContractRequestDate}
          employmentContractSigningDate={employmentContractSigningDate}
          employmentContractSportsBonuses={employmentContractSportsBonuses} />
      ) : null}
      {supplementaryFileType == "Contrato de Representacion" ? (
        <RepresentationContractShow
          supplementaryFileType={supplementaryFileType}
          representationContractAddress={representationContractAddress}
          representationContractBankDetails={representationContractBankDetails}
          representationContractConsideration={representationContractConsideration}
          representationContractFifaRegistrationNumber={representationContractFifaRegistrationNumber}
          representationContractFullName={representationContractFullName}
          representationContractNationality={representationContractNationality}
          representationContractOtherConditions={representationContractOtherConditions}
          representationContractPassportNumber={representationContractPassportNumber}
          representationContractRfcOrTaxId={representationContractRfcOrTaxId}
        />
      ) : null}
      {supplementaryFileType == "Contrato de Baja" ? (
        <TerminationShow
          supplementaryFileType={supplementaryFileType}
          terminationContractCategory={terminationContractCategory}
          terminationContractFullName={terminationContractFullName}
          terminationContractNotes={terminationContractNotes}
          terminationContractPaymentDate={terminationContractPaymentDate}
          terminationContractRequestDate={terminationContractRequestDate}
          terminationContractSigningDate={terminationContractSigningDate}
          terminationContractTerminationAmount={terminationContractTerminationAmount}
          terminationContractTerminationType={terminationContractTerminationType}
        />
      ) : null}
      {supplementaryFileType == "Contrato de Transferencia" ? (
        <TransferContractShow
          supplementaryFileType={supplementaryFileType}
          transferContractClubName={transferContractClubName}
          transferContractClubRepresentative={transferContractClubRepresentative}
          transferContractEmail={transferContractEmail}
          transferContractFreeOrCost={transferContractFreeOrCost}
          transferContractOtherConditions={transferContractOtherConditions}
          transferContractPurchaseOption={transferContractPurchaseOption}
          transferContractSolidarityWithholding={transferContractSolidarityWithholding}
          transferContractTransferType={transferContractTransferType}
          transferContractValidity={transferContractValidity}
        />
      ) : null}
    </div>
  );
}

export default ContractDetails;
