import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import logo from '../assets/images/logo.png';
import axios from 'axios';


const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    const data = {
      username: username,
      password: password
    }

    axios.post('https://supplies.bravos-fcjuarez.com/sign-in/', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
      .then(response => {
        onLogin({
          token: response.data.data.access_token,
          roles: response.data.roles,
          currentUser: response.data.name
        })
      })
      .catch(err => {
        console.log('An error has occurred: ' + err);
      })
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="login-container">
      <div className="left">
        <img className='logo' src={logo} alt="Logo" />
      </div>
      <div className="right">
        <div className="form-container">
          <h2 style={{ color: '#333333' }}>Iniciar Sesión</h2>
          <div>
            <TextField
              label="Usuario"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              fullWidth
              margin="dense"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div>
            <TextField
              label="Contraseña"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              margin="dense"
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className='button-section'>
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                width: '100%',
                height: '38px',
              }}
              variant="contained"
              onClick={handleLogin}
            >
              Entrar
              <KeyboardArrowRightIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
