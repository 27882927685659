const EmploymentContract = ({
  supplementaryFileType,
  employmentContractPosition,
  employmentContractAddress,
  employmentContractAnnualSalary,
  employmentContractCategory,
  employmentContractClubTerminationClause,
  employmentContractContractDuration,
  employmentContractContractType,
  employmentContractDateOfBirth,
  employmentContractDirectedTo,
  employmentContractEmail,
  employmentContractFirstPaymentDate,
  employmentContractFreeAgentOrTransfer,
  employmentContractFullName,
  employmentContractJurisdiction,
  employmentContractLastPaymentDate,
  employmentContractMaritalStatus,
  employmentContractNationality,
  employmentContractOtherBenefits,
  employmentContractPaymentFrequency,
  employmentContractPlayerTerminationClause,
  employmentContractRelocation,
  employmentContractRequestDate,
  employmentContractSigningDate,
  employmentContractSportsBonuses,
}) => {
  return (
    <div>
      <div style={{ textAlign: "center", marginBottom: "10vh" }}>
        <h3>DETALLES DEL CONTRATO</h3>
      </div>
      <div >
        <h2><b>{supplementaryFileType}</b></h2>
      </div>
      <div>
        <p>
          <b>Puesto:</b> {employmentContractPosition}
        </p>
        <p>
          <b>Dirección:</b> {employmentContractAddress}
        </p>
        <p>
          <b>Salario Anual:</b> {employmentContractAnnualSalary}
        </p>
        <p>
          <b>Categoria:</b> {employmentContractCategory}
        </p>
        <p>
          <b>Cláusula de Rescisión Del Club:</b> {employmentContractClubTerminationClause}
        </p>
        <p>
          <b>Duracion Del Contrato:</b> {employmentContractContractDuration}
        </p>
        <p>
          <b>Tipo De Contrato:</b> {employmentContractContractType}
        </p>
        <p>
          <b>Fecha De Nacimiento:</b> {employmentContractDateOfBirth}
        </p>
        <p>
          <b>Dirigido A:</b> {employmentContractDirectedTo}
        </p>
        <p>
          <b>Email:</b> {employmentContractEmail}
        </p>
        <p>
          <b>Fecha De Primer Pago:</b> {employmentContractFirstPaymentDate}
        </p>
        <p>
          <b>Agente Libre O Transferencia:</b> {employmentContractFreeAgentOrTransfer}
        </p>
        <p>
          <b>Nombre Completo:</b> {employmentContractFullName}
        </p>
        <p>
          <b>Jurisdiccion:</b> {employmentContractJurisdiction}
        </p>
        <p>
          <b>Fecha De Ultimo Pago:</b> {employmentContractLastPaymentDate}
        </p>
        <p>
          <b>Estado Civil:</b> {employmentContractMaritalStatus}
        </p>
        <p>
          <b>Nacionalidad:</b> {employmentContractNationality}
        </p>
        <p>
          <b>Otros Beneficios:</b> {employmentContractOtherBenefits}
        </p>
        <p>
          <b>Frecuencia De Pago:</b> {employmentContractPaymentFrequency}
        </p>
        <p>
          <b>Cláusula de Rescisión Del Jugador:</b> {employmentContractPlayerTerminationClause}
        </p>
        <p>
          <b>Traslado:</b> {employmentContractRelocation}
        </p>
        <p>
          <b>Fecha De Solicitud:</b> {employmentContractRequestDate}
        </p>
        <p>
          <b>Fecha De Firma:</b> {employmentContractSigningDate}
        </p>
        <p>
          <b>Bonos Deportivos:</b> {employmentContractSportsBonuses}
        </p>
      </div>
    </div>
  );
}

export default EmploymentContract;