import React, { useState, useEffect, forwardRef } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";

const AdministrationFileUploader = React.forwardRef((props, ref) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const token = localStorage.getItem('token');

  const handleFileChange = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async (contractId) => {
    console.log("Entrando a handleUpload de AdministrationFileUploader");
    if (!selectedFile) {
      console.error("No se ha seleccionado ningún archivo");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("contract_id", contractId);
    formData.append("administration_file", "administration_file");

    try {
      await axios.post(
        `https://supplies.bravos-fcjuarez.com/contracts/upload_file/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Archivo subido exitosamente");
    } catch (error) {
      console.error("Error al subir el archivo:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current = {
        handleUpload: handleUpload
      };
    }
  }, [ref, handleUpload]);

  return (
    <section id="administration-file-uploader-form">
      <div>
        <input type="file" onChange={handleFileChange} />
      </div>
    </section>
  );
});

export default AdministrationFileUploader;
