import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PDFViewer from "./PDF_Viewer";
import ContractsForm from "./ContractsForm";
import moment from "moment";
import "moment-timezone";
import "moment/locale/es";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardActionArea from "@mui/material/CardActionArea";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReasonModal from "./ReasonModal";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import { Token } from "@mui/icons-material";

const settings = ["Adjuntar PDF"];

function ContractsTable({
  socketRef,
  lastUpdate,
  currentUser,
  onShowToast,
  onClearToast,
}) {
  const [uploading, setUploading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const [anchorElPdf, setAnchorElPdf] = React.useState(null);
  const [editingContractId, setEditingContractId] = useState(null);
  const [contractHistory, setContractHistory] = useState({});
  const [openReasonsModal, setOpenReasonsModal] = useState(false);
  const [action, setAction] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [phases, setPhases] = useState([]);
  const [contractId, setContractId] = React.useState(null);
  const [contractsWithSupplementaryFiles, setContractsWithSupplementaryFiles] = useState(null);
  useEffect(() => {
    axios.get(`https://supplies.bravos-fcjuarez.com/contracts/contracts_with_supplementary_files/`)
      .then(response => {
        setContractsWithSupplementaryFiles(response.data.contract_ids);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const resetFilters = () => {
    setSelectedPhase("");
    setTimeout(() => {
      contractsPerPhase(token);
    }, 100);
  };

  const handleOpenReasonsModal = (contractId, action) => {
    setContractId(contractId);
    setAction(action);
    setOpenReasonsModal(true);
  };

  const handleCloseReasonsModal = () => {
    setContractId(null);
    setOpenReasonsModal(false);
  };

  const handleFetchHistory = async (contractId) => {
    try {
      const response = await axios.get(
        `https://supplies.bravos-fcjuarez.com/contracts/${contractId}/contract_history/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setContractHistory({ ...contractHistory, [contractId]: response.data });
    } catch (error) {
      console.error("Error al obtener el historial del contrato:", error);
      onShowToast(
        "Error",
        "Ocurrio un error al traer el historial." + error.response.data.message,
        "error"
      );
    }
  };

  const handleOpenPdfMenu = (event, rowId) => {
    setAnchorElPdf(event.currentTarget);
    document.getElementById(`file-upload[${rowId}]`).click();
  };

  const handleClosePdfMenu = (selectedSetting) => {
    // if (selectedSetting === 'Adjuntar PDF') {
    // }
    // setAnchorElPdf(null);
  };

  // Modal
  const handleOpenModal = (contractId) => {
    setEditingContractId(contractId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSignatureButtonClick = (contractId) => {
    openSignatureWindow(contractId, token);
  };

  const openSignatureWindow = (contractId, token) => {
    console.log("TABLE CONTRACT -> ", contractId);
    console.log("TABLE TOKEN -> ", token);
    history.push({
      pathname: "/signature",
      state: { contractId: contractId, token: token },
    });
  };

  const handleFileChange = async (event, rowID) => {
    const file = event.target.files[0];
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("contract_id", rowID);
    setTimeout(async () => {
      try {
        await axios.post(
          `https://supplies.bravos-fcjuarez.com/contracts/upload_file/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        handleAccept(rowID, null);
        if (socketRef.current) {
          socketRef.current.emit("message", "Archivo subido con éxito.");
        }
      } catch (error) {
        console.error("Error al subir el archivo:", error);
      } finally {
        setUploading(false);
        setSelectedRowId(null);
      }
    }, 500);
  };

  const getClassNameDescription = (phaseDescription) => {
    const strategy = {
      Rechazado: "phase-red phase-center",
      Solicitado: "phase-turquoise phase-center",
      "Revisión Legal": "phase-gray phase-center",
      "Esperando Contrato": "phase-mustard phase-center",
      "Revisión Administrativa": "phase-gold phase-center",
      "Revision Subpresidencia": "phase-blue phase-center",
      "Revisión Presidencia": "phase-pink phase-center",
      "Esperando Contrato Firmado": "phase_sg phase-center",
      Finalizado: "phase-green phase-center",
    };

    return strategy[phaseDescription] || "";
  };

  function Row(props) {
    const { row, onAccept, onReject } = props;
    const [open, setOpen] = React.useState(false);


    const handleToggleRow = (contractId, openState) => {
      setOpen((prevOpen) => !prevOpen);
      if (!openState && !contractHistory[contractId]) {
        handleFetchHistory(contractId);
      }
    };

    return (
      <React.Fragment>
        <ReasonModal
          open={openReasonsModal}
          handleClose={handleCloseReasonsModal}
          contractId={contractId}
          action={action}
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleGetContracts={contractsPerPhase}
        />
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleToggleRow(row.id)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center">{row.applicant}</TableCell>
          <TableCell align="center">{row.contract_title}</TableCell>
          <TableCell align="center">{row.area}</TableCell>
          {contractsWithSupplementaryFiles.includes(row.id) ? (
            <TableCell align="center">
              <Link className="description-link" to={`/contracts/${row.id}/details`}>
                {row.type_description}
              </Link>
            </TableCell>
          ) : <TableCell align="center">{row.type_description}</TableCell>}
          <Tooltip title={<h2>{row.request_reason}</h2>}>
            <TableCell
              align="center"
              style={{ whiteSpace: "normal", overflowWrap: "break-word" }}
            >
              {row.request_reason.length <= 19
                ? row.request_reason
                : row.request_reason.slice(0, 19) + "..."}
            </TableCell>
          </Tooltip>

          <TableCell align="center" sx={{ fontSize: ".8em" }} width={150}>
            <div>
              {row.phase_id == 1 && (
                <div>
                  <span style={{ color: "red" }}>*</span> Esperando corrección.
                </div>
              )}
              <div className={getClassNameDescription(row.phase_description)}>
                {row.phase_id == 8 ? (
                  "Esperando C. Firmado"
                ) : row.phase_description}
              </div>
            </div>
          </TableCell>
          <TableCell align="center">
            {moment
              .utc(row.created_at)
              .tz("America/Mexico_City")
              .locale("es")
              .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
          </TableCell>
          <TableCell align="center">
            {moment
              .utc(row.updated_at)
              .tz("America/Mexico_City")
              .locale("es")
              .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
          </TableCell>
          <TableCell className="actions" align="center">
            <div style={{ display: "flex" }}>
              {currentUser != "Humberto Valdes" && row.phase_id > 3 ? (
                <React.Fragment>
                  <button
                    onClick={() => {
                      if (!localStorage.getItem("roles").includes("Legal") && !localStorage.getItem("roles").includes("Presidencia")) {
                        return;
                      }
                      document.getElementById(`file-upload[${row.id}]`).click();
                    }}
                    disabled={!localStorage.getItem("roles").includes("Legal") && !localStorage.getItem("roles").includes("Presidencia") &&
                      (row.phase_id !== 4 && row.phase_id !== 8)
                    }
                    style={{
                      background: "none",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    <PictureAsPdfIcon
                      className={`pdf-icon ${row.attached_file || row.administration_attached_file ? "red-icon" : "grey-icon"
                        }`}
                    />
                  </button>
                  <input
                    id={`file-upload[${row.id}]`}
                    name={"file"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) => handleFileChange(event, row.id)}
                  />
                  {
                    row.attached_file ? (
                      <PDFViewer
                        attached_file={row.attached_file}
                        rowId={row.id}
                        socketRef={socketRef}
                        contractPhaseDescription={row.description}
                      />
                    ) : (
                      row.administration_attached_file && (
                        <PDFViewer
                          attached_file={row.administration_attached_file}
                          rowId={row.id}
                          socketRef={socketRef}
                          contractPhaseDescription={row.description}
                        />
                      )
                    )
                  }
                </React.Fragment>
              ) : null}

              {(currentUser == "Humberto Valdes" &&
                row.phase_description == "Rechazado") ||
                (currentUser == "Alejandra Torres" &&
                  row.phase_description == "Rechazado") ||
                (currentUser == "Cinthia Heredia" &&
                  row.phase_description == "Rechazado")
                ? (
                  <React.Fragment>
                    <Button
                      onClick={() => handleOpenModal(row.id)}
                      style={{ background: "#007bff" }}
                      sx={{ color: "#fff" }}
                      size="small"
                      variant="contained"
                    >
                      Editar
                    </Button>

                    <ContractsForm
                      isOpen={openModal && editingContractId === row.id}
                      handleClose={handleCloseModal}
                      token={token}
                      contractInformation={row}
                    />
                  </React.Fragment>
                ) : null}

              {!["Humberto Valdes", "Alejandra Torres", "Cinthia Heredia", "Mirele Perez"].includes(currentUser) &&
                [1, 2, 3, 5, 6, 7].includes(parseInt(row.phase_id))
                ? (
                  <Button
                    sx={{ color: "#fff" }}
                    className="action-buttons"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => handleOpenReasonsModal(row.id, "Aceptar")}
                  >
                    Aceptar
                  </Button>
                ) : null}

              {(parseInt(row.phase_id) === 2 &&
                !["Humberto Valdes", "Alejandra Torres", "Cinthia Heredia", "Mirele Perez"].includes(currentUser) &&
                parseInt(row.phase_id) !== 1 &&
                parseInt(row.phase_id) !== 4) ||
                parseInt(row.phase_id) === 3 ||
                parseInt(row.phase_id) === 5 ||
                parseInt(row.phase_id) === 6 ||
                parseInt(row.phase_id) == 7 ? (
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={() => handleOpenReasonsModal(row.id, "Rechazar")}
                >
                  Rechazar
                </Button>
              ) : null}
            </div>
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: "#ebe9eb" }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Historial de movimientos
                </Typography>
                {/* Historial */}
                {contractHistory !== null &&
                  contractHistory[row.id] &&
                  contractHistory[row.id].length > 0 ? (
                  <TableContainer sx={{ width: "50%" }} component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1em",
                              textAlign: "center",
                            }}
                            align="right"
                          >
                            Usuario
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1em",
                              textAlign: "center",
                            }}
                            align="right"
                          >
                            Acción
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1em",
                              textAlign: "center",
                            }}
                            align="right"
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "1em",
                              textAlign: "center",
                            }}
                            align="right"
                          >
                            Razón
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contractHistory[row.id].map((historyItem) => (
                          <TableRow key={historyItem.id}>
                            <TableCell align="left">
                              {historyItem.user}
                            </TableCell>
                            <TableCell align="center">
                              {historyItem.action}
                            </TableCell>
                            <TableCell align="center">
                              {moment
                                .utc(historyItem.action_date)
                                .tz("America/Mexico_City")
                                .locale("es")
                                .format("DD [de] MMMM [de] YYYY [a las] HH:mm")}
                            </TableCell>
                            <TableCell align="center">
                              {historyItem.reason}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div>No se encontraron movimientos para este contrato.</div>
                )}
                {/* Termina el historial */}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      applicant: PropTypes.string.isRequired,
      area: PropTypes.string.isRequired,
      phase_description: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type_description: PropTypes.string.isRequired,
      created_at: PropTypes.string.isRequired,
    }).isRequired,
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired,
  };

  const [rows, setRows] = useState([]);
  const [token, setToken] = useState("");

  const contractsPerPhase = (token, phaseId = null) => {
    const url =
      phaseId == null
        ? `https://supplies.bravos-fcjuarez.com/contracts_per_phase`
        : `https://supplies.bravos-fcjuarez.com/contracts_per_phase?phase_id=${phaseId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener datos de la API:", error);
        onShowToast(
          "Error",
          "Ocurrio un error al traer los contratos.",
          "error"
        );
      });
  };

  const handlePhaseChange = async (event) => {
    console.log(event.target.value);
    const selectedValue = event.target.value;
    setSelectedPhase(selectedValue);

    try {
      contractsPerPhase(token, event.target.value);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    setTimeout(async () => {
      contractsPerPhase(token);
      console.log("entro al useEffect de contractsTable");

      try {
        const response = await axios.get(
          `https://supplies.bravos-fcjuarez.com/get_contract_phases`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPhases(response.data);
      } catch (error) {
        console.error("Error al obtener las fases:", error);
        onShowToast("Error", "Ocurrio un error al traer las fases.", "error");
      }
    }, 500);
  }, [lastUpdate]);

  const handleAccept = async (contractId, reason = null) => {
    await axios
      .put(
        `https://supplies.bravos-fcjuarez.com/contracts/${contractId}/accept_contract/`,
        { reason: reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (response) => {
        console.log("Contrato actualizado:", response.data);
        // const formData = new FormData();
        // formData.append("contract", contractId);
        // formData.append("reason", reason);
        // formData.append("type", "accept");
        // setTimeout(async () => {
        //   await axios.post(
        //     `https://supplies.bravos-fcjuarez.com/send_whatsapp_notification`,
        //     formData,
        //     {
        //       headers: {
        //         "Content-Type": "multipart/form-data",
        //       },
        //     }
        //   );
        // }, 350);
      })
      .catch((error) => {
        console.error("Error al aceptar el contrato:", error);
        onShowToast("Error", "Ocurrio un error al aceptar el contrato. " + error.response.data.non_field_errors, "error");
      });
  };

  const handleReject = (contractId, reason) => {
    axios
      .put(
        `https://supplies.bravos-fcjuarez.com/contracts/${contractId}/reject_contract/`,
        { reason: reason },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const formData = new FormData();
        formData.append("contract", contractId);
        formData.append("reason", reason);
        formData.append("type", "reject");
        setTimeout(async () => {
          await axios.post(
            `https://supplies.bravos-fcjuarez.com/send_whatsapp_notification`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log("Contrato actualizado:", response.data);
          if (socketRef.current) {
            socketRef.current.emit("message", "Contrato actualizado.");
            console.log(
              "Contrato actualizado (emitido directamente en ContractsTable)."
            );
          }
        }, 350);
      });
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <div className="title">
        <h2>CONTRATOS</h2>
      </div>
      <section id="filters" style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "8px" }}>
          <Select
            value={selectedPhase}
            onChange={handlePhaseChange}
            displayEmpty
            inputProps={{ "aria-label": "Seleccione una fase" }}
          >
            <MenuItem value="" disabled>
              Seleccione una fase
            </MenuItem>
            {phases.map((phase) => (
              <MenuItem key={phase.id} value={phase.id}>
                {phase.description}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            style={{ color: "#fff" }}
            size="small"
            onClick={resetFilters}
          >
            Restablecer filtro
          </Button>
        </div>
      </section>

      <div className="contact-actions">
        {localStorage.getItem("roles") &&
          localStorage.getItem("roles").includes("Deportivo") ||
          localStorage.getItem("roles").includes("Administrativo") ||
          localStorage.getItem("roles").includes("Deportivo Femenil") ||
          localStorage.getItem("roles").includes("Fuerzas Basicas Varonil") ||
          localStorage.getItem("roles").includes("Deportivo Femenil Sub 19") ||
          localStorage.getItem("roles").includes("Legal") ? (
          <React.Fragment>
            <Button
              onClick={handleOpenModal}
              sx={{ color: "#fff" }}
              size="small"
              variant="contained"
              endIcon={<AddIcon />}
            >
              Agregar
            </Button>
            <ContractsForm
              isOpen={openModal}
              handleClose={handleCloseModal}
              token={token}
              contractInformation={null}
              onShowToast={onShowToast}
              onClearToast={onClearToast}
            />
          </React.Fragment>
        ) : null}
      </div>
      {isMobile ? (
        <div>
          {rows.length > 0 ? (
            rows.map((row) => (
              <Card
                key={row.id}
                sx={{ maxWidth: 345 }}
                style={{ marginBottom: "4vh" }}
              >
                <CardActionArea>
                  <CardContent style={{ background: "#fdfcfc" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{ marginBottom: "3vh" }}
                      >
                        {row.contract_title}
                      </Typography>
                      <Typography style={{ textAlign: "right" }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          {/* Inicio */}
                          {localStorage.getItem("roles").includes("Legal") &&
                            !localStorage
                              .getItem("roles")
                              .includes("Administrativo") && (
                              <React.Fragment>
                                <PictureAsPdfIcon
                                  onClick={() => {
                                    document
                                      .getElementById(`file-upload[${row.id}]`)
                                      .click();
                                  }}
                                  className={`pdf-icon ${row.attached_file ? "red-icon" : "grey-icon"
                                    }`}
                                />
                                <input
                                  id={`file-upload[${row.id}]`}
                                  name={"file"}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleFileChange(event, row.id)
                                  }
                                />
                                {row.attached_file && (
                                  <PDFViewer
                                    attached_file={row.attached_file}
                                    rowId={row.id}
                                    socketRef={socketRef}
                                    contractPhaseDescription={row.description}
                                  />
                                )}
                              </React.Fragment>
                            )}
                          {/* Fin */}
                          <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElPdf}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            open={Boolean(anchorElPdf)}
                            onClose={handleClosePdfMenu}
                          >
                            {settings.map((setting) => (
                              <MenuItem
                                key={setting}
                                onClick={() => handleClosePdfMenu(setting)}
                              >
                                <Typography textAlign="center">
                                  {setting}
                                </Typography>
                              </MenuItem>
                            ))}
                          </Menu>
                        </Box>
                      </Typography>
                    </div>
                    <Typography variant="body2">
                      {row.request_reason}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "25px",
                      }}
                    >
                      {row.phase_id == 1 && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ color: "red" }}>*</span> Esperando
                          corrección.
                        </div>
                      )}
                      <div
                        style={{
                          width: "100%",
                          marginTop: "20px",
                          textAlign: "center",
                        }}
                        className={
                          row.phase_description === "Rechazado"
                            ? "phase-red phase-center"
                            : row.phase_description === "Solicitado"
                              ? "phase-turquoise phase-center"
                              : row.phase_description === "Revisión Legal"
                                ? "phase-gray phase-center"
                                : row.phase_description === "Esperando Contrato"
                                  ? "phase-mustard phase-center"
                                  : row.phase_description ===
                                    "Revisión Administrativa"
                                    ? "phase-gold phase-center"
                                    : row.phase_description ===
                                      "Revision Subpresidencia"
                                      ? "phase-blue phase-center"
                                      : row.phase_description === "Revisión Presidencia"
                                        ? "phase-pink phase-center"
                                        : row.phase_description === "Finalizado"
                                          ? "phase-green phase-center"
                                          : ""
                        }
                      >
                        {row.phase_description}
                      </div>
                    </div>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {(currentUser == "Humberto Valdes" &&
                    row.phase_description == "Rechazado") ||
                    (currentUser == "Alejandra Torres" &&
                      row.phase_description == "Rechazado") &&
                    (currentUser == "Cinthia Heredia" &&
                      row.phase_description == "Rechazado") && (
                      <React.Fragment>
                        <Button
                          onClick={() => handleOpenModal(row.id)}
                          style={{ background: "#007bff" }}
                          sx={{ color: "#fff" }}
                          size="small"
                          variant="contained"
                        >
                          Editar
                        </Button>
                        <ContractsForm
                          isOpen={openModal && editingContractId === row.id}
                          handleClose={handleCloseModal}
                          token={token}
                          contractInformation={row}
                        />
                      </React.Fragment>
                    )}
                  {["Humberto Valdes", "Alejandra Torres", "Cinthia Heredia", "Mirele Perez"].includes(currentUser) &&
                    [1, 2, 3, 5, 6, 7].includes(parseInt(row.phase_id)) ? (
                    <Button
                      sx={{ color: "#fff" }}
                      className="action-buttons"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => handleOpenReasonsModal(row.id, "Aceptar")}
                    >
                      Aceptar
                    </Button>
                  ) : null}
                  {(parseInt(row.phase_id) === 2 &&
                    !["Humberto Valdes", "Alejandra Torres", "Cinthia Heredia", "Mirele Perez"].includes(currentUser) &&
                    parseInt(row.phase_id) !== 1 &&
                    parseInt(row.phase_id) !== 4) ||
                    parseInt(row.phase_id) === 3 ||
                    parseInt(row.phase_id) === 5 ||
                    parseInt(row.phase_id) === 6 ||
                    parseInt(row.phase_id) == 7 ? (
                    <Button
                      size="small"
                      color="error"
                      variant="contained"
                      onClick={() => handleOpenReasonsModal(row.id, "Rechazar")}
                    >
                      Rechazar
                    </Button>
                  ) : null}
                </CardActions>
                <ReasonModal
                  open={openReasonsModal}
                  handleClose={handleCloseReasonsModal}
                  contract={row}
                  action={action}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                />
              </Card>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                padding: "20px 0",
                color: "#333333",
              }}
            >
              No se encontraron registros.
            </div>
          )}
        </div>
      ) : (
        <TableContainer component={Paper} className="contracts-table">
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Solicitante
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Título de contrato
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Área
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Tipo de Contrato
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold" }}
                  maxWidth={30}
                >
                  Justificación de Contrato
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Fase Actual
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Fecha de Creación
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Última Actualización
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    onAccept={handleAccept}
                    onReject={handleReject}
                  />
                ))
              ) : (
                <TableRow sx={{ background: "#fffee8" }}>
                  <TableCell
                    colSpan={10}
                    style={{
                      textAlign: "center",
                      padding: "20px 0",
                      color: "#333333",
                    }}
                  >
                    No se encontraron registros.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default ContractsTable;
