import { create } from '@mui/material/styles/createTransitions';
import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    disableFormSubmit: true,
  },
  reducers: {
    setDisableFormSubmit: (state, action) => {
      state.disableFormSubmit = action.payload;
    }
  }
});

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    supplementaryFileType: "",
  },
  reducers: {
    setSupplementaryFileType: (state, action) => {
      state.supplementaryFileType = action.payload;
    }
  }
});

export const modificationFormSlice = createSlice({
  name: 'modificationForm',
  initialState: {
    fullName: "",
    category: "",
    requestDate: null,
    modificationsToMake: "",
    playerOrCoach: "Jugador"
  },
  reducers: {
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setRequestDate: (state, action) => {
      state.requestDate = action.payload
    },
    setModificationsToMake: (state, action) => {
      state.modificationsToMake = action.payload;
    },
    setPlayerOrCoach: (state, action) => {
      state.playerOrCoach = action.payload;
    },
  },
});

export const terminationFormSlice = createSlice({
  name: 'terminationForm',
  initialState: {
    terminationTerminationType: "Jugador",
    terminationCategory: "",
    terminationRequestDate: null,
    terminationFullName: "",
    terminationTerminationAmount: "",
    terminationPaymentDate: null,
    terminationSigningDate: null,
    terminationNotes: ""
  },
  reducers: {
    setTerminationType: (state, action) => {
      state.terminationTerminationType = action.payload;
    },
    setTerminationCategory: (state, action) => {
      state.terminationCategory = action.payload;
    },
    setTerminationRequestDate: (state, action) => {
      state.terminationRequestDate = action.payload;
    },
    setTerminationFullName: (state, action) => {
      state.terminationFullName = action.payload;
    },
    setTerminationAmount: (state, action) => {
      state.terminationTerminationAmount = action.payload;
    },
    setTerminationPaymentDate: (state, action) => {
      state.terminationPaymentDate = action.payload;
    },
    setTerminationSigningDate: (state, action) => {
      state.terminationSigningDate = action.payload;
    },
    setTerminationNotes: (state, action) => {
      state.terminationNotes = action.payload;
    }
  }
})

export const transferFormSlice = createSlice({
  name: 'transferForm',
  initialState: {
    transferClubName: "",
    transferClubRepresentative: "",
    transferEmail: "",
    transferTransferType: "Temporal",
    transferValidity: null,
    transferFreeOrCost: "",
    transferPurchaseOption: "",
    transferSolidarityWithholding: "",
    transferOtherConditions: ""
  },
  reducers: {
    setTransferClubName: (state, action) => {
      state.transferClubName = action.payload;
    },
    setTransferClubRepresentative: (state, action) => {
      state.transferClubRepresentative = action.payload;
    },
    setTransferEmail: (state, action) => {
      state.transferEmail = action.payload;
    },
    setTransferTransferType: (state, action) => {
      state.transferTransferType = action.payload;
    },
    setTransferValidity: (state, action) => {
      state.transferValidity = action.payload;
    },
    setTransferFreeOrCost: (state, action) => {
      state.transferFreeOrCost = action.payload;
    },
    setTransferPurchaseOption: (state, action) => {
      state.transferPurchaseOption = action.payload;
    },
    setTransferSolidarityWithholding: (state, action) => {
      state.transferSolidarityWithholding = action.payload;
    },
    setTransferOtherConditions: (state, action) => {
      state.transferOtherConditions = action.payload;
    }
  }
})

export const representationFormSlice = createSlice({
  name: 'representationForm',
  initialState: {
    representationFullName: "",
    representationNationality: "",
    representationAddress: "",
    representationRfcOrTaxId: "",
    representationPassportNumber: "",
    representationFifaRegistrationNumber: "",
    representationConsideration: "",
    representationOtherConditions: "",
    representationBankDetails: "",
    representationJurisdiction: "FIFA"
  },
  reducers: {
    setRepresentationFullName: (state, action) => {
      console.log(action.payload);
      state.representationFullName = action.payload;
      console.log("cambia el full_name");
    },
    setRepresentationNationality: (state, action) => {
      state.representationNationality = action.payload;
    },
    setRepresentationAddress: (state, action) => {
      state.representationAddress = action.payload;
    },
    setRepresentationRfcOrTaxId: (state, action) => {
      state.representationRfcOrTaxId = action.payload;
    },
    setRepresentationPassportNumber: (state, action) => {
      state.representationPassportNumber = action.payload;
    },
    setRepresentationFifaRegistrationNumber: (state, action) => {
      state.representationFifaRegistrationNumber = action.payload;
    },
    setRepresentationConsideration: (state, action) => {
      state.representationConsideration = action.payload;
    },
    setRepresentationOtherConditions: (state, action) => {
      state.representationOtherConditions = action.payload;
    },
    setRepresentationBankDetails: (state, action) => {
      state.representationBankDetails = action.payload;
    },
    setRepresentationJurisdiction: (state, action) => {
      state.representationJurisdiction = action.payload;
    }
  }
})

export const employmentFormSlice = createSlice({
  name: "employmentForm",
  initialState: {
    employmentPosition: "",
    employmentContractType: "Contrato",
    employmentDirectedTo: "Jugador",
    employmentCategory: "",
    employmentRequestDate: null,
    employmentFullName: "",
    employmentNationality: "",
    employmentDateOfBirth: null,
    employmentAddress: "",
    employmentMaritalStatus: "",
    employmentEmail: "",
    employmentFreeAgentOrTransfer: "",
    employmentRelocation: "",
    employmentContractDuration: null,
    employmentAnnualSalary: "",
    employmentFirstPaymentDate: null,
    employmentLastPaymentDate: null,
    employmentPaymentFrequency: "Mensual",
    employmentSportsBonuses: "",
    employmentOtherBenefits: "",
    employmentPlayerTerminationClause: "",
    employmentClubTerminationClause: "",
    employmentJurisdiction: "FIFA",
    employmentSigningDate: null
  },
  reducers: {
    setEmploymentPosition: (state, action) => {
      state.employmentPosition = action.payload;
    },
    setEmploymentContractType: (state, action) => {
      state.employmentContractType = action.payload;
    },
    setEmploymentDirectedTo: (state, action) => {
      state.employmentDirectedTo = action.payload;
    },
    setEmploymentCategory: (state, action) => {
      state.employmentCategory = action.payload;
    },
    setEmploymentRequestDate: (state, action) => {
      state.employmentRequestDate = action.payload;
    },
    setEmploymentFullName: (state, action) => {
      state.employmentFullName = action.payload;
    },
    setEmploymentNationality: (state, action) => {
      state.employmentNationality = action.payload;
    },
    setEmploymentDateOfBirth: (state, action) => {
      state.employmentDateOfBirth = action.payload;
    },
    setEmploymentAddress: (state, action) => {
      state.employmentAddress = action.payload;
    },
    setEmploymentMaritalStatus: (state, action) => {
      state.employmentMaritalStatus = action.payload;
    },
    setEmploymentEmail: (state, action) => {
      state.employmentEmail = action.payload;
    },
    setEmploymentFreeAgentOrTransfer: (state, action) => {
      state.employmentFreeAgentOrTransfer = action.payload;
    },
    setEmploymentRelocation: (state, action) => {
      state.employmentRelocation = action.payload;
    },
    setEmploymentContractDuration: (state, action) => {
      state.employmentContractDuration = action.payload;
    },
    setEmploymentAnnualSalary: (state, action) => {
      state.employmentAnnualSalary = action.payload;
    },
    setEmploymentFirstPaymentDate: (state, action) => {
      state.employmentFirstPaymentDate = action.payload;
    },
    setEmploymentLastPaymentDate: (state, action) => {
      state.employmentLastPaymentDate = action.payload;
    },
    setEmploymentPaymentFrequency: (state, action) => {
      state.employmentPaymentFrequency = action.payload;
    },
    setEmploymentSportsBonuses: (state, action) => {
      state.employmentSportsBonuses = action.payload;
    },
    setEmploymentOtherBenefits: (state, action) => {
      state.employmentOtherBenefits = action.payload;
    },
    setEmploymentPlayerTerminationClause: (state, action) => {
      state.employmentPlayerTerminationClause = action.payload;
    },
    setEmploymentClubTerminationClause: (state, action) => {
      state.employmentClubTerminationClause = action.payload;
    },
    setEmploymentJurisdiction: (state, action) => {
      state.employmentJurisdiction = action.payload;
    },
    setEmploymentSigningDate: (state, action) => {
      state.employmentSigningDate = action.payload;
    }
  }
})

export const {
  setDisableFormSubmit
} = formSlice.actions;

export const {
  setFullName,
  setCategory,
  setRequestDate,
  setModificationsToMake,
  setPlayerOrCoach
} = modificationFormSlice.actions;

export const {
  setTerminationType,
  setTerminationCategory,
  setTerminationRequestDate,
  setTerminationFullName,
  setTerminationAmount,
  setTerminationPaymentDate,
  setTerminationSigningDate,
  setTerminationNotes
} = terminationFormSlice.actions;

export const {
  setTransferClubName,
  setTransferClubRepresentative,
  setTransferEmail,
  setTransferTransferType,
  setTransferValidity,
  setTransferFreeOrCost,
  setTransferPurchaseOption,
  setTransferSolidarityWithholding,
  setTransferOtherConditions
} = transferFormSlice.actions;

export const {
  setRepresentationFullName,
  setRepresentationNationality,
  setRepresentationAddress,
  setRepresentationRfcOrTaxId,
  setRepresentationPassportNumber,
  setRepresentationFifaRegistrationNumber,
  setRepresentationConsideration,
  setRepresentationOtherConditions,
  setRepresentationBankDetails,
  setRepresentationJurisdiction
} = representationFormSlice.actions;

export const {
  setEmploymentPosition,
  setEmploymentContractType,
  setEmploymentDirectedTo,
  setEmploymentCategory,
  setEmploymentRequestDate,
  setEmploymentFullName,
  setEmploymentNationality,
  setEmploymentDateOfBirth,
  setEmploymentAddress,
  setEmploymentMaritalStatus,
  setEmploymentEmail,
  setEmploymentFreeAgentOrTransfer,
  setEmploymentRelocation,
  setEmploymentContractDuration,
  setEmploymentAnnualSalary,
  setEmploymentFirstPaymentDate,
  setEmploymentLastPaymentDate,
  setEmploymentPaymentFrequency,
  setEmploymentSportsBonuses,
  setEmploymentOtherBenefits,
  setEmploymentPlayerTerminationClause,
  setEmploymentClubTerminationClause,
  setEmploymentJurisdiction,
  setEmploymentSigningDate
} = employmentFormSlice.actions;

export const {
  setSupplementaryFileType
} = generalSlice.actions;

export const modificationFormReducer = modificationFormSlice.reducer;
export const generalReducer = generalSlice.reducer;
export const terminationFormReducer = terminationFormSlice.reducer;
export const transferFormReducer = transferFormSlice.reducer;
export const representationFormReducer = representationFormSlice.reducer;
export const employmentFormReducer = employmentFormSlice.reducer;
export const generalFormReducer = formSlice.reducer;