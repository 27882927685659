import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const ReasonModal = ({
  open,
  handleClose,
  contractId,
  action,
  handleAccept,
  handleReject,
}) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      if (action == "Aceptar") {
        await handleAccept(contractId, reason);
      }

      if (action == "Rechazar") {
        await handleReject(contractId, reason);
      }
    } catch (error) {

    } finally {
      setReason("");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
        }}
      >
        <h3 id="modal-modal-title">
          Por favor, indique su motivo para aprobar o rechazar el contrato.
        </h3>
        <TextareaAutosize
          id="reason"
          placeholder="Escriba su motivo aquí (máximo 500 palabras)"
          maxRows={10}
          minRows={7}
          style={{ width: "100%", marginBottom: "16px", resize: "vertical" }}
          value={reason}
          onChange={handleReasonChange}
        />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            sx={{ width: "48%", color: "#fff" }}
          >
            Enviar
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            sx={{ width: "48%" }}
          >
            Cancelar
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ReasonModal;
