import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
  setRepresentationFullName,
  setRepresentationNationality,
  setRepresentationAddress,
  setRepresentationRfcOrTaxId,
  setRepresentationPassportNumber,
  setRepresentationFifaRegistrationNumber,
  setRepresentationConsideration,
  setRepresentationOtherConditions,
  setRepresentationBankDetails,
  setRepresentationJurisdiction,
  setSupplementaryFileType
} from '../slices';

function RepresentationForm() {
  const dispatch = useDispatch();
  const {
    representationFullName,
    representationNationality,
    representationAddress,
    representationRfcOrTaxId,
    representationPassportNumber,
    representationFifaRegistrationNumber,
    representationConsideration,
    representationOtherConditions,
    representationBankDetails,
    representationJurisdiction
  } = useSelector((state) => state.representationForm);

  useEffect(() => {
    dispatch(setSupplementaryFileType("representation contract"));
  }, []);

  return (
    <section id="termination-form">
      <div style={{ textAlign: "center" }}>
        <h2>Formato representación</h2>
      </div>
      <TextField
        label="Nombre completo"
        variant="outlined"
        value={representationFullName}
        onChange={(e) => dispatch(setRepresentationFullName(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Nacionalidad"
        variant="outlined"
        value={representationNationality}
        onChange={(e) => dispatch(setRepresentationNationality(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Domicilio"
        variant="outlined"
        value={representationAddress}
        onChange={(e) => dispatch(setRepresentationAddress(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="RFC / Tax ID"
        variant="outlined"
        value={representationRfcOrTaxId}
        onChange={(e) => dispatch(setRepresentationRfcOrTaxId(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Número de pasaporte"
        variant="outlined"
        value={representationPassportNumber}
        onChange={(e) => dispatch(setRepresentationPassportNumber(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Número de registro FIFA"
        variant="outlined"
        value={representationFifaRegistrationNumber}
        onChange={(e) => dispatch(setRepresentationFifaRegistrationNumber(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Contraprestación"
        variant="outlined"
        value={representationConsideration}
        onChange={(e) => dispatch(setRepresentationConsideration(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Otras condiciones"
        variant="outlined"
        value={representationOtherConditions}
        onChange={(e) => dispatch(setRepresentationOtherConditions(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Detalles bancarios"
        variant="outlined"
        value={representationBankDetails}
        onChange={(e) => dispatch(setRepresentationBankDetails(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <FormControl style={{ marginBottom: '15px' }}>
        <FormLabel id="demo-radio-buttons-group-label">Jurisdicción</FormLabel>
        <RadioGroup
          style={{ marginLeft: '10px', color: "rgba(0, 0, 0, 0.54)" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="FIFA"
          name="radio-buttons-group"
          value={representationJurisdiction}
          onChange={(e) => dispatch(setRepresentationJurisdiction(e.target.value))}
        >
          <FormControlLabel value="FIFA" control={<Radio />} label="FIFA" />
          <FormControlLabel value="CCRC" control={<Radio />} label="CCRC" />
        </RadioGroup>
      </FormControl>
    </section>
  );
}

export default RepresentationForm;
