import React, { useState, useEffect, useRef } from "react";
import TerminationForm from "./TerminationForm";
import TransferForm from "./TransferForm";
import EmploymentForm from "./EmploymentForm";
import RepresentationForm from "./RepresentationForm";
import ModificationForm from "./ModificationForm";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';


import {
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import axios from "axios";
import AdministrationFileUploader from "./AdministrationFileUploader";
import { setDisableFormSubmit } from "../slices";


const ContractsForm = ({
  isOpen,
  handleClose,
  token,
  contractInformation,
  onShowToast,
}) => {
  const [area, setArea] = useState("");
  const [contractType, setContractType] = useState("");
  const [contracts, setContracts] = useState([]);
  const [contractTitle, setContractTitle] = useState("");
  const [justification, setJustification] = useState("");
  const [buttonText, setButtonText] = useState("");
  const dispatch = useDispatch();
  const { disableFormSubmit } = useSelector((state) => state.inputsForm);

  const adminFileUploaderRef = useRef(null);

  const {
    fullName,
    category,
    requestDate,
    modificationsToMake,
    playerOrCoach } = useSelector((state) => state.modificationForm);

  const { supplementaryFileType } = useSelector((state) => state.general);

  const {
    terminationTerminationType,
    terminationCategory,
    terminationRequestDate,
    terminationFullName,
    terminationTerminationAmount,
    terminationPaymentDate,
    terminationSigningDate,
    terminationNotes,
  } = useSelector((state) => state.terminationForm);

  const {
    transferClubName,
    transferClubRepresentative,
    transferEmail,
    transferTransferType,
    transferValidity,
    transferFreeOrCost,
    transferPurchaseOption,
    transferSolidarityWithholding,
    transferOtherConditions
  } = useSelector((state) => state.transferForm);

  const {
    representationFullName,
    representationNationality,
    representationAddress,
    representationRfcOrTaxId,
    representationPassportNumber,
    representationFifaRegistrationNumber,
    representationConsideration,
    representationOtherConditions,
    representationBankDetails,
    representationJurisdiction
  } = useSelector((state) => state.representationForm);

  const {
    employmentPosition,
    employmentContractType,
    employmentDirectedTo,
    employmentCategory,
    employmentRequestDate,
    employmentFullName,
    employmentNationality,
    employmentDateOfBirth,
    employmentAddress,
    employmentMaritalStatus,
    employmentEmail,
    employmentFreeAgentOrTransfer,
    employmentRelocation,
    employmentContractDuration,
    employmentAnnualSalary,
    employmentFirstPaymentDate,
    employmentLastPaymentDate,
    employmentPaymentFrequency,
    employmentSportsBonuses,
    employmentOtherBenefits,
    employmentPlayerTerminationClause,
    employmentClubTerminationClause,
    employmentJurisdiction,
    employmentSigningDate
  } = useSelector((state) => state.employmentForm);


  const formik = useFormik({
    initialValues: {
      area: area,
      contractType: '',
      contractTitle: '',
      justification: ''
    },
    validate: values => {
      const errors = {};
      if (!values.contractType) {
        errors.contractType = 'Debe seleccionar un tipo de contrato';
      }
      if (!values.contractTitle) {
        errors.contractTitle = 'Debe ingresar un título de contrato';
      }
      if (!values.justification) {
        errors.justification = 'Debe ingresar una justificación';
      }
      return errors;
    },
    onSubmit: values => {
      const formData = {
        applicant: localStorage.getItem("currentUser"),
        area: area,
        type: formik.values.contractType,
        contract_title: formik.values.contractTitle,
        request_reason: formik.values.justification,
      };

      if (buttonText === "Editar") {
        axios
          .put(
            `https://supplies.bravos-fcjuarez.com/contracts/${contractInformation.id}/update_contract/`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((response) => {
            console.log("Contrato editado exitosamente:", response.data);
            onShowToast("Éxito", "Contrato editado exitosamente", "success");
            handleClose();
          })
          .catch((error) => {
            console.error("Error al editar el contrato:", error);
            onShowToast("Error", "Error al editar el contrato", "error");
          });

        return;
      }

      if (buttonText === "Solicitar") {
        axios
          .post("https://supplies.bravos-fcjuarez.com/contracts/", formData, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            console.log("Contrato solicitado exitosamente:", response.data);
            onShowToast("Éxito", "Contrato solicitado exitosamente", "success");

            if (contractType == 11) {
              setTimeout(() => {
                adminFileUploaderRef.current.handleUpload(response.data.id);
              }, 150);
            }

            if (supplementaryFileType == "modification agreement") {
              createModificationAgreement(response.data.id);
            }

            if (supplementaryFileType == "termination") {
              createTermination(response.data.id);
            }

            if (supplementaryFileType == "transfer contract") {
              createTransferContract(response.data.id);
            }

            if (supplementaryFileType == "representation contract") {
              createRepresentationContract(response.data.id);
            }

            if (supplementaryFileType == "employment contract") {
              createEmploymentContract(response.data.id);
            }


            handleClose();
            cleanDataForm();
          })
          .catch((error) => {
            console.error("Error al solicitar el contrato:", error);
            onShowToast("Error", error.response.data.message, "error");
            cleanDataForm();
          });
      }

      handleClose();
    }
  });

  useEffect(() => {
    if (formik.values.contractType != 2) {
      dispatch(setDisableFormSubmit(false));
    } else {
      dispatch(setDisableFormSubmit(true));
    }
  }, [formik.values.contractType])

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        if (contractInformation) {
          setContractTitle(contractInformation.contract_title);
          setJustification(contractInformation.request_reason);
          setButtonText("Editar");
        } else {
          setButtonText("Solicitar");
        }

        try {
          const response = await axios.get(
            "https://supplies.bravos-fcjuarez.com/get_contract_types",
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          setContracts(response.data);

          if (contractInformation) {
            const selectedContract = response.data.find(
              (contract) =>
                contract.description === contractInformation.type_description
            );
            setContractType(selectedContract.id);
          }
        } catch (error) {
          console.error("Error fetching contract types:", error);
          onShowToast(
            "Error",
            "Error al cargar los tipos de contrato",
            "error"
          );
        }

        try {
          const response = await axios.post(
            "https://supplies.bravos-fcjuarez.com/set_area",
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          const areaFromServer = response.data.area;
          setArea(areaFromServer);
          formik.values.area = area;
        } catch (error) {
          console.error("Error fetching area:", error);
        }
      }
    };

    fetchData();
  }, [isOpen, token, contractInformation]);

  // const handleButtonClick = () => {
  //   setShowToast(true);
  // };

  const prepareDateForDatabase = (dateToFormat) => {
    let date = new Date(dateToFormat);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const createTermination = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      termination_type: terminationTerminationType,
      termination_amount: terminationTerminationAmount,
      signing_date: prepareDateForDatabase(terminationSigningDate),
      request_date: prepareDateForDatabase(terminationRequestDate),
      payment_date: prepareDateForDatabase(terminationPaymentDate),
      notes: terminationNotes,
      full_name: terminationFullName,
      contract: contractId,
      category: terminationCategory,
    };

    axios.post(`https://supplies.bravos-fcjuarez.com/forms/`, formData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("Ocurrio un error el intentar anexar el contrato de terminación: ", err);
      })
  }

  const createModificationAgreement = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      agreement_type: playerOrCoach,
      category: category,
      request_date: prepareDateForDatabase(requestDate),
      full_name: fullName,
      modifications_to_make: modificationsToMake,
      contract: contractId
    };

    axios.post(`https://supplies.bravos-fcjuarez.com/forms/`, formData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("Ocurrio un error el intentar anexar el convenio modificatorio: ", err);
      })
  }

  const createTransferContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      club_name: transferClubName,
      club_representative: transferClubRepresentative,
      email: transferEmail,
      transfer_type: transferTransferType,
      validity: prepareDateForDatabase(transferValidity),
      free_or_cost: transferFreeOrCost,
      purchase_option: transferPurchaseOption,
      solidarity_withholding: transferSolidarityWithholding,
      other_conditions: transferOtherConditions,
      contract: contractId
    }

    axios.post(`https://supplies.bravos-fcjuarez.com/forms/`, formData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("Ocurrio un error el intentar anexar el contrato de transferencia: ", err);
      })
  }

  const createRepresentationContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      full_name: representationFullName,
      nationality: representationNationality,
      address: representationAddress,
      rfc_or_tax_id: representationRfcOrTaxId,
      passport_number: representationPassportNumber,
      fifa_registration_number: representationFifaRegistrationNumber,
      consideration: representationConsideration,
      other_conditions: representationOtherConditions,
      bank_details: representationBankDetails,
      jurisdiction: representationJurisdiction,
      contract: contractId
    }

    axios.post(`https://supplies.bravos-fcjuarez.com/forms/`, formData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log("Ocurrio un error el intentar anexar el contrato de representación: ", err);
      })
  }

  const createEmploymentContract = (contractId) => {
    const formData = {
      supplementary_file_type: supplementaryFileType,
      position: employmentPosition,
      contract_type: employmentContractType,
      directed_to: employmentDirectedTo,
      category: employmentCategory,
      request_date: prepareDateForDatabase(employmentRequestDate),
      full_name: employmentFullName,
      nationality: employmentNationality,
      date_of_birth: prepareDateForDatabase(employmentDateOfBirth),
      address: employmentAddress,
      marital_status: employmentMaritalStatus,
      email: employmentEmail,
      free_agent_or_transfer: employmentFreeAgentOrTransfer,
      relocation: employmentRelocation,
      contract_duration: prepareDateForDatabase(employmentContractDuration),
      annual_salary: employmentAnnualSalary,
      first_payment_date: prepareDateForDatabase(employmentFirstPaymentDate),
      last_payment_date: prepareDateForDatabase(employmentLastPaymentDate),
      payment_frequency: employmentPaymentFrequency,
      sports_bonuses: employmentSportsBonuses,
      other_benefits: employmentOtherBenefits,
      player_termination_clause: employmentPlayerTerminationClause,
      club_termination_clause: employmentClubTerminationClause,
      jurisdiction: employmentJurisdiction,
      signing_date: prepareDateForDatabase(employmentSigningDate),
      contract: contractId
    }

    setTimeout(() => {
      axios.post(`https://supplies.bravos-fcjuarez.com/forms/`, formData,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.log("Ocurrio un error el intentar anexar el contrato de trabajo: ", err);
        })
    }, 500);
  }

  const handleModalClick = (event) => {
    if (event.target === event.currentTarget) {
      cleanDataForm();
      handleClose();
    }
  };

  const cleanDataForm = () => {
    setContractTitle("");
    setJustification("");
    setContractType("");
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          onClick={handleModalClick}
        >
          <div
            style={{
              height: "60vh",
              background: "#fff",
              padding: "2rem",
              width: 1000,
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              overflowY: "auto"
            }}
          >
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
              <Typography variant="h6">Formulario de Contrato</Typography>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Solicitante"
                variant="outlined"
                disabled
                value={localStorage.getItem("currentUser")}
                style={{ marginBottom: "1rem", width: "100%" }}
              />
              <div style={{ marginBottom: "1rem" }}>
                <TextField
                  label="Área"
                  variant="outlined"
                  disabled
                  value={formik.values.area}
                  onChange={formik.handleChange}
                  name="area"
                  style={{ width: "100%" }}
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel>Tipo de Contrato</InputLabel>
                  <Select
                    value={formik.values.contractType}
                    onChange={formik.handleChange}
                    name="contractType"
                    label="Tipo de Contrato"
                  >
                    {contracts.map((contract) => (
                      <MenuItem key={contract.id} value={contract.id}>
                        {contract.description}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.contractType && (
                    <div style={{ color: 'red' }}>{formik.errors.contractType}</div>
                  )}
                </FormControl>
              </div>
              <TextField
                label="Título de Contrato"
                variant="outlined"
                value={formik.values.contractTitle}
                onChange={formik.handleChange}
                name="contractTitle"
                inputProps={{ maxLength: 70 }}
                style={{ marginBottom: "1rem", width: "100%" }}
              />
              {formik.errors.contractTitle && (
                <div style={{ color: 'red' }}>{formik.errors.contractTitle}</div>
              )}
              <TextareaAutosize
                aria-label="Justificación de Contrato"
                placeholder="Justificación de Contrato"
                multiline
                rows={7}
                value={formik.values.justification}
                onChange={formik.handleChange}
                name="justification"
                maxLength={200}
                style={{ width: "100%", marginBottom: "1rem" }}
              />
              {formik.errors.justification && (
                <div style={{ color: 'red' }}>{formik.errors.justification}</div>
              )}

              {formik.values.contractType == 1 ? (
                <TransferForm />
              ) : null}

              {formik.values.contractType == 2 && Object.keys(formik.errors).length === 0 ? (
                <EmploymentForm />
              ) : null}

              {formik.values.contractType == 3 ? (
                <RepresentationForm />
              ) : null}

              {formik.values.contractType == 5 ? (
                <ModificationForm />
              ) : null}

              {formik.values.contractType == 6 || formik.values.contractType == 7 ? (
                <TerminationForm />
              ) : null}

              {formik.values.contractType == 11 ? (
                <AdministrationFileUploader
                  ref={adminFileUploaderRef}
                />
              ) : null}
              <div id="actions" style={{ textAlign: "right" }}>
                {contractInformation?.phase_description ===
                  "Por definir o rechazado" ? (
                  <Button
                    id="edit-button"
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    Editar
                  </Button>
                ) : (
                  <Button
                    id="request-button"
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={disableFormSubmit}
                  >
                    Solicitar
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ContractsForm;
