import React, { useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextField, TextareaAutosize } from "@material-ui/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFullName,
  setCategory,
  setRequestDate,
  setModificationsToMake,
  setPlayerOrCoach,
  setSupplementaryFileType
} from '../slices';

function ModificationForm() {
  const dispatch = useDispatch();
  const {
    fullName,
    category,
    requestDate,
    modificationsToMake,
    playerOrCoach
  } = useSelector((state) => state.modificationForm);

  useEffect(() => {
    dispatch(setSupplementaryFileType("modification agreement"));
  }, []);

  return (
    <section id="modification-form">
      <div style={{ textAlign: "center" }}>
        <h2>Formato de modificación</h2>
      </div>
      <FormControl style={{ marginBottom: '15px' }}>
        <FormLabel id="demo-radio-buttons-group-label">Jugador o Cuerpo Técnico</FormLabel>
        <RadioGroup
          style={{ marginLeft: '10px', color: "rgba(0, 0, 0, 0.54)" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Jugador"
          name="radio-buttons-group"
          value={playerOrCoach}
          onChange={(e) => dispatch(setPlayerOrCoach(e.target.value))}
        >
          <FormControlLabel value="Jugador" control={<Radio />} label="Jugador" />
          <FormControlLabel value="Cuerpo Técnico" control={<Radio />} label="Cuerpo Técnico" />
        </RadioGroup>
      </FormControl>
      <TextField
        label="Nombre completo"
        variant="outlined"
        value={fullName}
        onChange={(e) => dispatch(setFullName(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Categoría"
        variant="outlined"
        value={category}
        onChange={(e) => dispatch(setCategory(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <div style={{ marginBottom: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de solicitud"
            value={requestDate}
            onChange={(date) => dispatch(setRequestDate(date))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <TextareaAutosize
        aria-label="Modificaciones a realizar"
        placeholder="Modificaciones a realizar"
        multiline
        rows={7}
        value={modificationsToMake}
        onChange={(e) => dispatch(setModificationsToMake(e.target.value))}
        maxLength={200}
        style={{ width: "100%", marginBottom: "15px" }}
      />
    </section>
  );
}

export default ModificationForm;
