import React, { useState, useEffect, useRef } from "react";
import Login from "../src/components/Login";
import "../src/assets/styles/styles.sass";
import Navbar from "../src/components/Navbar";
import ContractsTable from "../src/components/ContractsTable";
import io from "socket.io-client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignatureWindow from "../src/components/SignatureWindow";
import ContractDetails from "./components/ContractDetails";
import Toast from "./components/toast/Toast";
import { Provider } from 'react-redux';
import { store } from "./store";

const theme = createTheme({
  palette: {
    primary: {
      main: "#21cb35",
    },
  },
});

const socketConfig = {
  autoConnect: true,
  reconnection: true,
  transports: ["websocket"],
};

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [roles, setRoles] = useState(localStorage.getItem("roles"));
  const [currentUser, setUser] = useState(localStorage.getItem("currentUser"));
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState(null);

  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const socketRef = useRef(null);

  const handleCloseToast = () => {
    setToastData(null);
    setShowToast(false);
  };

  const handleShowToast = (title, message, type) => {
    setToastData({ title, message, type });
    setShowToast(true);
  };

  const handleLogin = ({ token, roles, currentUser }) => {
    localStorage.setItem("token", token);
    localStorage.setItem("roles", roles);
    localStorage.setItem("currentUser", currentUser);
    setToken(token);
    setRoles(roles);
    setUser(currentUser);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    setToken(null);
    setRoles(null);
  };

  useEffect(() => {
    const sse = new EventSource(
      "https://supplies.bravos-fcjuarez.com/events/?chanel=updates",
      {
        withCredentials: false,
      }
    );

    sse.onmessage = (event) => {
      const dataEvent = JSON.parse(event.data);
      handleShowToast("Actualización", dataEvent.text, dataEvent.type);
      setLastUpdate(dataEvent);
    };

    // return () => {
    //   if (socketRef.current) {
    //     socketRef.current.disconnect();
    //   }
    // };
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <div>
          <Router>
            <Switch>
              <Route path="/" exact>
                {token ? (
                  <div>
                    <div className="container">
                      <Navbar onLogout={handleLogout} />
                      {showToast && (
                        <Toast
                          title={toastData.title}
                          message={toastData.message}
                          type={toastData.type}
                          closeToast={handleCloseToast}
                        />
                      )}
                      <ContractsTable
                        socketRef={socketRef}
                        lastUpdate={lastUpdate}
                        currentUser={currentUser}
                        onShowToast={handleShowToast}
                        onClearToast={handleCloseToast}
                      />
                    </div>
                  </div>
                ) : (
                  <Login onLogin={handleLogin} />
                )}
              </Route>
              <Route path="/signature">
                <SignatureWindow />
              </Route>
            </Switch>
            <Route path="/contracts/:id/details">
              <ContractDetails
                token={token} />
            </Route>
          </Router>
        </div>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
