import React, { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextField, TextareaAutosize } from "@material-ui/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTransferClubName,
  setTransferClubRepresentative,
  setTransferEmail,
  setTransferTransferType,
  setTransferValidity,
  setTransferFreeOrCost,
  setTransferPurchaseOption,
  setTransferSolidarityWithholding,
  setTransferOtherConditions,
  setSupplementaryFileType
} from '../slices';

function TransferForm() {
  const dispatch = useDispatch();
  const {
    transferClubName,
    transferClubRepresentative,
    transferEmail,
    transferTransferType,
    transferValidity,
    transferFreeOrCost,
    transferPurchaseOption,
    transferSolidarityWithhholding,
    transferOtherConditions
  } = useSelector((state) => state.transferForm);

  useEffect(() => {
    dispatch(setSupplementaryFileType("transfer contract"));
  }, []);

  return (
    <section id="termination-form">
      <div style={{ textAlign: "center" }}>
        <h2>Formato de transferencia</h2>
      </div>
      <TextField
        label="Nombre del club"
        variant="outlined"
        value={transferClubName}
        onChange={(e) => dispatch(setTransferClubName(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Nombre del representante"
        variant="outlined"
        value={transferClubRepresentative}
        onChange={(e) => dispatch(setTransferClubRepresentative(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Email"
        variant="outlined"
        value={transferEmail}
        onChange={(e) => dispatch(setTransferEmail(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <FormControl style={{ marginBottom: '15px' }}>
        <FormLabel id="demo-radio-buttons-group-label">Temporal o definitiva</FormLabel>
        <RadioGroup
          style={{ marginLeft: '10px', color: "rgba(0, 0, 0, 0.54)" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Temporal"
          name="radio-buttons-group"
          value={transferTransferType}
          onChange={(e) => dispatch(setTransferTransferType(e.target.value))}
        >
          <FormControlLabel value="Temporal" control={<Radio />} label="Temporal" />
          <FormControlLabel value="Definitiva" control={<Radio />} label="Definitiva" />
        </RadioGroup>
      </FormControl>
      <div style={{ marginBottom: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Vigencia"
            value={transferValidity}
            onChange={(date) => dispatch(setTransferValidity(date))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <TextField
        label="Gratuita o con costo"
        variant="outlined"
        value={transferFreeOrCost}
        onChange={(e) => dispatch(setTransferFreeOrCost(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Opción de compra"
        variant="outlined"
        value={transferPurchaseOption}
        onChange={(e) => dispatch(setTransferPurchaseOption(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextField
        label="Retención solidaria"
        variant="outlined"
        value={transferSolidarityWithhholding}
        onChange={(e) => dispatch(setTransferSolidarityWithholding(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <TextareaAutosize
        aria-label="Otras condiciones"
        placeholder="Otras condiciones"
        multiline
        rows={7}
        value={transferOtherConditions}
        onChange={(e) => dispatch(setTransferOtherConditions(e.target.value))}
        maxLength={200}
        style={{ width: "100%", marginBottom: "15px" }}
      />
    </section>
  );
}

export default TransferForm;
