const TransferContractShow = ({
  supplementaryFileType,
  transferContractClubName,
  transferContractClubRepresentative,
  transferContractEmail,
  transferContractFreeOrCost,
  transferContractOtherConditions,
  transferContractPurchaseOption,
  transferContractSolidarityWithholding,
  transferContractTransferType,
  transferContractValidity
}) => {
  return (

    <div>
      <div style={{ textAlign: "center", marginBottom: "10vh" }}>
        <h3>DETALLES DEL CONTRATO</h3>
      </div>
      <div >
        <h2><b>{supplementaryFileType}</b></h2>
      </div>
      <div>
        <p>
          <b>Nombre del Club:</b> {transferContractClubName}
        </p>
        <p>
          <b>Representante del Club:</b> {transferContractClubRepresentative}
        </p>
        <p>
          <b>Email:</b> {transferContractEmail}
        </p>
        <p>
          <b>Gratuita O Con Costo:</b> {transferContractFreeOrCost}
        </p>
        <p>
          <b>Otras Condiciones:</b> {transferContractOtherConditions}
        </p>
        <p>
          <b>Opcion De Compra:</b> {transferContractPurchaseOption}
        </p>
        <p>
          <b>Retención de Solidaridad:</b> {transferContractSolidarityWithholding}
        </p>
        <p>
          <b>Temporal o Definitiva:</b> {transferContractTransferType}
        </p>
        <p>
          <b>Vigencia</b> {transferContractValidity}
        </p>
      </div>
    </div>
  );
}

export default TransferContractShow;