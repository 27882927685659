import React, { useState, useEffect } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TextField, TextareaAutosize } from "@material-ui/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTerminationType,
  setTerminationCategory,
  setTerminationRequestDate,
  setTerminationFullName,
  setTerminationAmount,
  setTerminationPaymentDate,
  setTerminationSigningDate,
  setTerminationNotes,
  setSupplementaryFileType
} from '../slices';

function TerminationForm() {
  const dispatch = useDispatch();

  const {
    terminationTerminationType,
    terminationCategory,
    terminationRequestDate,
    terminationFullName,
    terminationTerminationAmount,
    terminationPaymentDate,
    terminationSigningDate,
    terminationNotes, } = useSelector((state) => state.terminationForm);


  useEffect(() => {
    dispatch(setSupplementaryFileType("termination"));
  }, []);

  return (
    <section id="termination-form">
      <div style={{ textAlign: "center" }}>
        <h2>Formato de baja</h2>
      </div>
      <TextField
        label="Nombre completo"
        variant="outlined"
        value={terminationFullName}
        onChange={(e) => dispatch(setTerminationFullName(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <FormControl style={{ marginBottom: '15px' }}>
        <FormLabel id="demo-radio-buttons-group-label">Jugador o Cuerpo Técnico</FormLabel>
        <RadioGroup
          style={{ marginLeft: '10px', color: "rgba(0, 0, 0, 0.54)" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Jugador"
          value={terminationTerminationType}
          onChange={(e) => dispatch(setTerminationType(e.target.value))}
          name="radio-buttons-group"
        >
          <FormControlLabel value="Jugador" control={<Radio />} label="Jugador" />
          <FormControlLabel value="Cuerpo Técnico" control={<Radio />} label="Cuerpo Técnico" />
        </RadioGroup>
      </FormControl>
      <TextField
        label="Categoría"
        variant="outlined"
        value={terminationCategory}
        onChange={(e) => dispatch(setTerminationCategory(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <div style={{ marginBottom: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de solicitud"
            value={terminationRequestDate}
            onChange={(date) => dispatch(setTerminationRequestDate(date))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>

      <TextField
        label="Monto por finiquito"
        variant="outlined"
        value={terminationTerminationAmount}
        onChange={(e) => dispatch(setTerminationAmount(e.target.value))}
        style={{ width: "100%", marginBottom: '15px' }}
      />
      <div style={{ marginBottom: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de pago"
            value={terminationPaymentDate}
            onChange={(date) => dispatch(setTerminationPaymentDate(date))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Fecha de firma"
            value={terminationSigningDate}
            onChange={(date) => dispatch(setTerminationSigningDate(date))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <TextareaAutosize
        aria-label="Anotaciones"
        placeholder="Anotaciones"
        multiline
        rows={7}
        value={terminationNotes}
        onChange={(e) => dispatch(setTerminationNotes(e.target.value))}
        maxLength={200}
        style={{ width: "100%", marginBottom: "15px" }}
      />
    </section>
  );
}

export default TerminationForm;
