import React, { useState, useEffect } from "react";
import "./Toast.css";

const Toast = ({ title, message, type = "success", duration = 3000, closeToast }) => {
  const [isVisible, setIsVisible] = useState(true);
  const icons = {
    success: "https://img.icons8.com/color/48/000000/ok--v1.png",
    error: "https://img.icons8.com/bubbles/50/error.png",
    warning: "https://img.icons8.com/color/48/000000/warning-shield.png",
    info: "https://img.icons8.com/color/48/000000/info--v1.png",
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      closeToast();
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (
    <>
      {isVisible && (
        <div className="toast-container">
          <div className="toast">
            <div className="toast__icon">
              <img src={icons[type]} alt={type} />
            </div>
            <div className="toast__body">
              <h3 className="toast__title">{title}</h3>
              <p className="toast__msg">{message}</p>
            </div>
            <div className="toast__close">
              <i className="fas fa-times"></i>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Toast;
