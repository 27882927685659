const ModificationAgreementShow = ({
  supplementaryFileType,
  category, fullName,
  modificationsToMake, requestDate }) => {
  return (

    <div>
      <div style={{ textAlign: "center", marginBottom: "10vh" }}>
        <h3>DETALLES DEL CONTRATO</h3>
      </div>
      <div >
        <h2><b>{supplementaryFileType}</b></h2>
      </div>
      <div>
        <p>
          <b>Tipo de contrato anexado:</b> {supplementaryFileType}
        </p>
        <p>
          <b>Categoria:</b> {category}
        </p>
        <p>
          <b>Nombre Completo:</b> {fullName}
        </p>
        <p>
          <b>Modificaciones a realizar:</b> {modificationsToMake}
        </p>
        <p>
          <b>Fecha de solicitud:</b> {requestDate}
        </p>
      </div>
    </div>
  );
}

export default ModificationAgreementShow;