const RepresentationContractShow = ({
  supplementaryFileType,
  representationContractAddress,
  representationContractBankDetails,
  representationContractConsideration,
  representationContractFifaRegistrationNumber,
  representationContractFullName,
  representationContractNationality,
  representationContractOtherConditions,
  representationContractPassportNumber,
  representationContractRfcOrTaxId
}) => {
  return (

    <div>
      <div style={{ textAlign: "center", marginBottom: "10vh" }}>
        <h3>DETALLES DEL CONTRATO</h3>
      </div>
      <div >
        <h2><b>{supplementaryFileType}</b></h2>
      </div>
      <div>
        <p>
          <b>Direccion:</b> {representationContractAddress}
        </p>
        <p>
          <b>Detalles Bancarios:</b> {representationContractBankDetails}
        </p>
        <p>
          <b>Consideracion:</b> {representationContractConsideration}
        </p>
        <p>
          <b>Numero de Registro FIFA:</b> {representationContractFifaRegistrationNumber}
        </p>
        <p>
          <b>Nombre Completo:</b> {representationContractFullName}
        </p>
        <p>
          <b>Nacionalidad:</b> {representationContractNationality}
        </p>
        <p>
          <b>Otras Condiciones:</b> {representationContractOtherConditions}
        </p>
        <p>
          <b>Numero de Pasaporte:</b> {representationContractPassportNumber}
        </p>
        <p>
          <b>RFC o Tax ID:</b> {representationContractRfcOrTaxId}
        </p>
      </div>
    </div>
  );
}

export default RepresentationContractShow;